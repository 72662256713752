import { ReactNode, useRef, useEffect } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import Tooltip from "../Tooltip/Tooltip";
import { translate } from "utils";

interface Props {
  label?: string;
  name: string;
  value?: string | number;
  onChange?(e: any): void;
  onKeyUp?(e: any): void;
  onKeyDown?(e: any): void;
  setTime?(e: any): void;
  onBlur?(e: any): void;
  onPaste?(e: any): void;
  onClick?(e: any): void;
  type?: string;
  inputMode?: "none" | "text" | "numeric" | "decimal";
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  hintText?: string;
  hintIcon?: string;
  selectTime?: boolean;
  icon?: { component: ReactNode; action?(): void };
  placeholder?: string;
  errorMessage?: string | boolean;
  fluid?: boolean;
  validation?: boolean;
  rows?: number;
  durationValue?: number;
  tooltip?: string;
  tooltipType?: "default" | "solid";
  readOnly?: boolean;
  dropdown?: boolean;
  dropValue?;
  curencyList?;
  isReplyMessage?: boolean;
  isPromotions?: boolean;
  gray?: boolean;
  textAlign?: boolean;
  durationOptions?;
  dataTestidName?;
  dataTestidAbout?;
  selectName?: string;
  children?: ReactNode;
}
function Field({
  label,
  name,
  value,
  onChange,
  onClick,
  type = "text",
  inputMode = "text",
  placeholder,
  required = false,
  disabled = false,
  onKeyUp,
  onKeyDown,
  onBlur,
  onPaste,
  setTime,
  hintText,
  hintIcon,
  selectTime,
  icon = {
    component: null,
  },
  fluid = false,
  validation = false,
  error = false,
  errorMessage,
  rows = 10,
  durationValue = 10,
  tooltip,
  tooltipType = "default",
  readOnly = false,
  dropdown,
  dropValue,
  curencyList,
  isReplyMessage,
  isPromotions,
  gray,
  textAlign,
  durationOptions,
  dataTestidName,
  dataTestidAbout,
  selectName,
  children,
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleFocus = () => {
    const inputElement = inputRef.current;
    const { value } = inputElement;
    inputElement.setSelectionRange(value.length + 1, value.length + 1);
  };

  useEffect(() => {
    if (isReplyMessage && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [isReplyMessage]);

  const fieldProps = {
    id: name,
    name,
    inputMode,
    disabled,
    required,
    placeholder,
    className: `disabled:opacity-50 disabled:bg-gray-100 text-sm appearance-none block w-full px-3 py-2 border border-gray-300 rounded-lg ${
      hintText ? "rounded-l-none" : hintIcon ? "rounded-l-none" : null
    } ${selectTime ? "rounded-r-none" : null} ${
      isPromotions ? "rounded-l-none" : null
    } ${gray ? "text-gray-500 bg-gray-100 " : null} ${
      textAlign ? "text-center" : null
    } shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm ${
      (required && validation) || errorMessage || error
        ? "border-red-300 focus:border-red-500 focus:ring-red-500"
        : "focus:border-indigo-500 focus:ring-indigo-500"
    } ${icon.component ? "pr-7 truncate" : ""}${
      readOnly ? "cursor-default" : ""
    }`,
    value,
    onChange,
    onBlur,
    onKeyUp,
    onKeyDown,
    onPaste,
    onClick,
  };
  return (
    <div className={fluid ? "w-full" : ""}>
      {label ? (
        <label
          htmlFor={name}
          className="flex items-center text-sm font-medium text-gray-700"
        >
          <span className="mr-1">{label}</span>
          <Tooltip tooltip={tooltip} type={tooltipType} />
        </label>
      ) : null}
      <div className="w-full mt-1 relative">
        <div className="flex flex-grow relative">
          {hintText ? (
            <div className="flex items-center text-xs sm:text-sm text-slate-700 whitespace-nowrap px-2 bg-slate-100 border-2 border-r-0 rounded-l-lg">
              {hintText}
            </div>
          ) : hintIcon ? (
            <div className="flex items-center text-xs sm:text-sm text-slate-700 whitespace-nowrap px-2 bg-slate-100 border-2 border-r-0 rounded-l-lg">
              <img src={hintIcon} alt="" />
            </div>
          ) : dropdown ? (
            <select
              data-testid="price-select-id"
              id={selectName}
              className="flex items-center text-xs sm:text-sm text-slate-700 whitespace-nowrap pl-4 pr-6 border-gray-300 mr-1 bg-slate-100 border rounded-lg"
              {...dropValue}
            >
              {curencyList.map((currency) => (
                <option key={currency} value={currency}>
                  {currency}
                </option>
              ))}
            </select>
          ) : null}
          <div className="flex w-full">
            {type === "textarea" ? (
              <textarea
                data-testid={dataTestidAbout ? "about-input" : null}
                ref={textareaRef}
                {...fieldProps}
                rows={rows}
              />
            ) : (
              <>
                {isPromotions ? (
                  <div className="flex items-center gap-3 bg-gray-100 border border-r-0 border-gray-300 rounded-l-lg shadow-sm cursor-pointer">
                    <span className="text-gray-500 hidden md:block bg-gray-100 border-0 rounded-l-lg whitespace-nowrap cursor-pointer sm:text-sm px-4">
                      https://waylight.me/
                    </span>
                    <img
                      className="block md:hidden p-2"
                      src="/img/logo-mobile-promotions.svg"
                      alt=""
                    />
                  </div>
                ) : null}
                <input
                  data-testid={dataTestidName ? "name-input" : null}
                  type={type}
                  {...fieldProps}
                  ref={inputRef}
                  onFocus={handleFocus}
                  key={name}
                  readOnly={readOnly}
                  className={`${fieldProps.className} ${
                    children
                      ? "direction-rtl overflow-hidden text-ellipsis whitespace-nowrap pr-32"
                      : ""
                  }`}
                />
                {children && (
                  <div className="absolute right-[120px] top-[3px] transform -translate-y-1/2">
                    {children}
                  </div>
                )}

                {selectTime ? (
                  <div className="flex items-center gap-3 bg-gray-100 border border-l-0 border-gray-300 rounded-r-lg shadow-sm cursor-pointer">
                    <select
                      id="duration"
                      name="duration"
                      required
                      className="text-gray-500 bg-gray-100 border-0 rounded-r-lg whitespace-nowrap cursor-pointer sm:text-sm"
                      onChange={setTime}
                      value={durationValue}
                    >
                      {durationOptions.map(({ value, label }) => (
                        <option key={value} value={value}>
                          {label.toLowerCase()}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : null}
              </>
            )}
          </div>
          {required && validation ? (
            <ExclamationCircleIcon className="w-5 h-5 text-red-500 absolute top-1/2 right-2 transform -translate-y-1/2" />
          ) : null}
        </div>
        {required && validation ? (
          <span className="block text-sm text-red-500 mt-1 error-msg">
            {translate("invalid")}
            {` ${label}.`}
          </span>
        ) : null}
        {errorMessage ? (
          <span
            className={
              dropdown
                ? "text-center block text-sm text-red-500 mt-1 error-price"
                : "block text-sm text-red-500 mt-1 error-msg"
            }
          >
            {errorMessage}
          </span>
        ) : null}
        {icon.component ? (
          <div
            onClick={icon.action}
            className="absolute right-2.5 top-0 flex items-center h-full cursor-pointer"
          >
            {icon.component}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Field;
